
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import BoardList from '@/components/board/BoardList.vue'

@Options({
  components: { BoardList },
  directives: { maska },
})
export default class Login extends Vue {}
